import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import {DomIf} from '@polymer/polymer/lib/elements/dom-if.js';
import '@banno/platform-ux-shared/components/polymer3/jha/forms/jha-form-floating-group.js';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-button.js';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-link-button.js';
import '@banno/platform-ux-shared/components/polymer3/jha/thumbnail/jha-thumbnail.js';
import AuthController from '@banno/platform-ux-shared/controllers/auth-controller.js';
import {routingMixin as RouterMixin} from '@jack-henry/web-component-router';
/**
 * @constructor
 * @polymer
 * @extends {PolymerElement}
 */
const ForgotPasswordContainerElementBase = RouterMixin(PolymerElement);
/** @polymer */
class ForgotPasswordContainerElement extends ForgotPasswordContainerElementBase {
  static get is() {
    return 'forgot-password-container';
  }
  static get properties() {
    return {
      email: {
        type: String,
        value: ''
      },
      validEmail: {
        type: Boolean,
        value: false
      },
      sendError: {
        type: Boolean,
        value: false
      },
      sendSuccess: {
        type: Boolean,
        value: false
      }
    };
  }

  constructor() {
    super();
    this.boundKeyHandler_ = this._keyHandler.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('keydown', this.boundKeyHandler_);
    this.sendSuccess = false;
    this.sendError = false;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('keydown', this.boundKeyHandler_);
  }

  _keyHandler(e) {
    const ENTER = 13;
    const pressedKey = e.keyCode;
    if (pressedKey === ENTER) {
      e.preventDefault();
      if (this.validEmail) {
        this._send();
      }
    }
  }

  _checkValidity(event) {
    this.validEmail = event.target.checkValidity();
  }

  _hasValidEmail(email, validEmail) {
    if (email.length > 0 && validEmail) {
      return true;
    }
    return false;
  }

  _send() {
    this.sendError = false;
    AuthController.forgotPassword(this.email).then((user) => {
      this.sendSuccess = true;
    }).catch((error) => {
      this.sendError = true;
    });
  }

  static get template() {
    return html`
    <style>
      :host {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 90%;
        min-height: 0;
        max-height: none;
        max-width: 550px;
        border-radius: 2px;
        box-shadow: 0 3px 12px 0 rgba(37,49,62,.24),0 3px 24px rgba(37,49,62,.12);
        border: none;
        margin: auto;
        padding: 0;
        background: white;
      }
      header,
      form,
      article,
      footer {
        text-align: center;
      }
      header {
        padding: 16px 16px 0;
      }
      h2 {
        color: var(--jha-text-dark);
        margin: 12px 0 24px;
        font-size: 16px;
        font-weight: 600;
      }
      article,
      form {
        padding: 16px;
        margin: 0;
      }
      jha-form-floating-group {
        width: 300px;
      }
      #sendErrorMessage {
        color: red;
        font-size: 12px;
        padding-top: 32px;
        margin-bottom: 16px;
      }
      .confirmation-message {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        text-align: left;
        box-sizing: border-box;
      }
      h3 {
        font-size: 18px;
        margin-bottom: 12px;
      }
      .confirmation-message > div p:first-of-type {
        font-size: 16px;
        margin-top: 0;
      }
      .confirmation-message > div p:last-of-type {
        margin-bottom: 0;
      }
      footer {
        width: 100%;
        border-top: 1px solid var(--jha-border-color);
        padding: 16px;
        box-sizing: border-box;
      }
      @media (min-width: 740px) {
        header {
          padding: 32px 32px 0;
        }
        h2 {
          font-size: 18px;
          font-weight: 400;
        }
        form,
        footer {
          padding: 32px;
        }
        .confirmation-message {
          padding: 16px 24px;
        }
      }
      @media (max-width: 480px) {
        :host {
          width: 100%;
          height: 100%;
          border-radius: 0;
        }
      }
    </style>
    <template is="dom-if" if="[[!sendSuccess]]">
      <header>
        <h2>Reset your password</h2>
        <template is="dom-if" if="[[sendError]]">
          <span id="sendErrorMessage">There was an error processing your request. Try again.</span>
        </template>
      </header>
      <form>
        <jha-form-floating-group id="username-group">
          <!-- eslint-disable @banno/ux/no-auto-binding -->
          <input id="email" type="email" value="{{email::input}}" on-input="_checkValidity" spellcheck="false" autocomplete="username" autocorrect="off" autocapitalize="off">
          <div class="label">Email</div>
        </jha-form-floating-group>
        <jha-button type="button" on-click="_send" disabled$="[[!_hasValidEmail(email, validEmail)]]">
          Email me instructions
        </jha-button>
      </form>
    </template>
    <template is="dom-if" if="[[sendSuccess]]">
      <article class="confirmation-message">
        <jha-thumbnail x-large="">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 290" enable-background="new 0 0 310 290">
            <path fill="#AA2B65" d="M259 112.3h39.1v171.6c0 2.8-2.3 5-5 5h-281.1c-2.8 0-5-2.3-5-5"></path>
            <path fill="#6962C4" d="M12 112.3h286v171.6c0 2.8-2.3 5-5 5h-281c-2.8 0-5-2.3-5-5v-171.6h5z"></path>
            <path fill="#5850BD" d="M159.1 229.7c-5.4 5.4-14.2 5.4-19.6 0l-101.8-101.8c-5.4-5.4-5.4-14.2 0-19.6l101.7-101.8c5.4-5.4 14.2-5.4 19.6 0l101.8 101.8c5.4 5.4 5.4 14.2 0 19.6l-101.7 101.8z"></path>
            <path fill="#F2F2F2" d="M261.8 21.2h-219.4c-6.4 0-11.6 5.2-11.6 11.6v216.9c0 6.4 5.2 11.6 11.6 11.6h219.5c6.4 0 11.6-5.2 11.6-11.6v-216.9c-.1-6.4-5.3-11.6-11.7-11.6z"></path>
            <path fill="#DDDFE8" d="M30.8 112.3h242.6v100.7h-242.6z"></path>
            <path fill="#4341A5" d="M298.1 112.8l-24.7-19.5v20.6z"></path>
            <path fill="#5850BD" d="M7 112.3l23.8-19v20.2z"></path>
            <circle fill="#4CC0C1" cx="268.5" cy="74.7" r="39.5"></circle>
            <path fill="#fff" d="M268.5 66.9l-4.9 1.2-1.3-5 8-2.4h4.2v28.2h-6.1v-22z"></path>
            <path fill="#C5C8D3" d="M66.5 130h167.2v11.2h-167.2zM109.5 77h76v8h-76zM109.5 58.6h38v8h-38zM66.5 152.5h167.2v11.2h-167.2zM66.5 175.1h167.2v11.2h-167.2z"></path>
            <path fill="#7B75CB" d="M298.1 125v158.9c0 2.8-2.3 5-5.1 5h-281.9c-1.7 0-3.2-.9-4.1-2.2l291.1-174.5v12.8z"></path>
            <path fill="#8D88D2" d="M7 125v158.9c0 2.8 2.3 5 5 5h281c1.7 0 3.2-.9 4.1-2.2l-290.1-174.4v12.7z"></path>
            <path opacity=".05" fill="#2B2C35" d="M297.2 286.8l-266.4-174.5h-23.8z"></path>
            <path fill="#C5C8D3" d="M97.8 64.3c-.1-.2-.2-.4-.4-.7-2.1-3.6-9.4-10.1-11.6-11.3-2.4-1.3-18.1 1.7-20.3 4-1.7 1.8-4.1 9.9-4.4 15.3-.1 1.5 0 2.9.3 3.7.7 1.9 3.2 4.4 5.7 6.9 2.6 2.6 5.3 5.1 7.2 6.1.8.5 2.2.6 3.8.6 5.7-.2 14.5-2.9 16.2-4.9 2.3-2.5 5.1-16.2 3.5-19.7zm-1.5-1.1h-1.7c-.2 0-.4 0-.5.1l-2.2.3-2.2.4-2.2.4-.8.2c-.4 6.9-2.6 18.7-4.9 21.8-.6.8-1.2 1.4-1.8 1.8h-.1v-.1c.4-.3.8-.7 1.1-1.2.2-.2.3-.4.4-.7l.4-.7c.4-1 .7-2 .9-3.1.1-.5.2-1 .3-1.4-5.1-4.1-10.3-9.4-13.6-13.6l-.4.1c-.4.1-.7.1-1.1.1l-1.1.1-1.1.2c-.7.2-1.4.4-2 .7-.6.4-1.2.8-1.7 1.4h-.1v-.1c.5-.6 1.1-1.2 2-1.7 5.7-3.4 26.7-5.3 30.7-5.3.7 0 1.6.2 1.7.3z"></path>
          </svg>
        </jha-thumbnail>
        <div>
          <h3>Please check your email.</h3>
          <p>We've sent password reset instructions.</p>
          <p>Be sure to check your spam filter if you haven't received the message within a few minutes.</p>
        </div>
      </article>
    </template>
    <footer>
      <jha-link-button link href="/a/login">
        Back to login
      </jha-link-button>
    </footer>
  `;
  }
}
customElements.define(ForgotPasswordContainerElement.is, ForgotPasswordContainerElement);
export default ForgotPasswordContainerElement;
